export const en = {
  // topnav
  Search: 'Search',
  FullScreen: 'Full screen',
  Refresh: 'Refresh',
  selected: 'selected',

  LogIn: 'Log In',
  LogOut: 'Log Out',
  SignUp: 'Sign Up',
  SignIn: 'Sign In',

  // sidenav
  Alerts: 'Alerts',
  Heartbeats: 'Heartbeats',
  Users: 'Users',
  Groups: 'Groups',
  Customers: 'Customers',
  Blackouts: 'Blackouts',
  Permissions: 'Permissions',
  APIKeys: 'API Keys',
  Labels: 'Labels',
  Searches: 'Searches',
  Reports: 'Reports',
  More: 'More',
  Settings: 'Settings',
  Help: 'Help',
  About: 'About',

  // more
  ChooseDisplayDensity: 'Choose a display density',
  DisplayDensity: 'Display density',
  Comfortable: 'Comfortable',
  Compact: 'Compact',
  Panel: 'Panel',
  Hide: 'Hide',
  Show: 'Show',
  DownloadAsCsv: 'Download as CSV',

  // Groups store
  UserAddedGroup: 'User added to group.',
  UserRemovedGroup: 'User removed from group.',

  // Preferences store
  SettingsSaved: 'Settings saved.',
  SettingsReset: 'Settings reset to defaults.',
  SettingsError: 'Could not retrieve user preferences.',

  // Users store
  UserStatusSaved: 'User status saved.',
  EmailSaved: 'Email verified saved.',

  // statuses
  Open: 'Open',
  Assign: 'Assign',
  Ack: 'Ack',
  Shelved: 'Shelved',
  Blackout: 'Blackout',
  Closed: 'Closed',
  Expired: 'Expired',
  Unknown: 'Unknown',
  NotValid: 'Not Valid',

  // operator actions
  Open: 'Open',
  Assign: 'Assign',
  Ack: 'Ack',
  Unack: 'Unack',
  Shelve: 'Shelve',
  Unshelve: 'Unshelve',
  Close: 'Close',
  Watch: 'Watch',
  Unwatch: 'Unwatch',
  AddNote: 'Add note',
  Delete: 'Delete',

  // Alert actions
  TextIsRequired: 'Text is required',
  TextMustBeLessThan: 'Text must be less than',
  characters: 'characters',

  // Alert detail
  AlertId: 'Alert ID',
  AlertOrNoteId: 'Alert/Note ID',
  LastReceiveAlertId: 'Last Receive Alert ID',
  CreateTime: 'Create Time',
  ReceiveTime: 'Receive Time',
  LastReceiveTime: 'Last Receive Time',
  Customer: 'Customer',
  Service: 'Service',
  Environment: 'Environment',
  Resource: 'Resource',
  Event: 'Event',
  Correlate: 'Correlate',
  Group: 'Group',
  Severity: 'Severity',
  Status: 'Status',
  Value: 'Value',
  Text: 'Text',
  TrendIndication: 'Trend Indication',
  Timeout: 'Timeout',
  Type: 'Type',
  DuplicateCount: 'Duplicate count',
  Repeat: 'Repeat',
  Origin: 'Origin',
  Tags: 'Tags',
  Attributes: 'Attributes',
  History: 'History',
  Data: 'Data',

  Details: 'Details',
  addedNoteOn: 'added note on',
  by: 'by',

  // Alert history
  UpdateTime: 'Update Time',
  Updated: 'Updated',
  User: 'User',

  // Alert list
  Loading: 'Loading',
  NoDataAvailable: 'No data available',
  ALL: 'ALL',
  Description: 'Description',
  Attribute: 'Attribute',
  TimeoutLeft: 'Timeout',
  Dupl: 'Dupl.',
  PrevSeverity: 'Prev. Severity',
  Duration: 'Duration',
  LastReceiveId: 'Last Receive ID',
  LastNote: 'Last Note',

  // Alert list filter
  Filters: 'Filters',
  FilterDescription: 'Filter results by text search',
  AllEnvironments: 'All environments',
  EnvironmentDescription: 'Choose one or more environment',
  AllSeverities: 'All severities',
  SeverityDescription: 'Choose one or more severity',
  AllStatuses: 'All statuses',
  StatusDescription: 'Choose one or more status',
  AllCustomers: 'All customers',
  CustomerDescription: 'Choose one or more customer',
  AllServices: 'All services',
  ServiceDescription: 'Choose one or more service',
  AllGroups: 'All groups',
  GroupDescription: 'Choose one or more group',

  Latest: 'Latest',
  Hour: '1 hour',
  SixHours: '6 hours',
  TwelveHours: '12 hours',
  SelectRange: 'Select Range',

  DateTime: 'Date/Time',
  StartDate: 'Start Date',
  EndDate: 'End Date',
  Time: 'Time',
  Apply: 'Apply',
  Reset: 'Reset',

  // API keys
  APIKey: 'API Key',
  Search: 'Search',
  Customer: 'Customer',
  Scopes: 'Scopes',
  Expires: 'Expires',
  Active: 'Active',
  Expired: 'Expired',
  Slow: 'Slow',

  User: 'User',
  LastUsed: 'Last Used',
  Actions: 'Actions',

  Copy: 'Copy',
  Copied: 'Copied!',
  NewApiKey: 'New API Key',
  EditApiKey: 'Edit API Key',

  // Blackouts
  ChooseService: 'Choose one or more service',
  Start: 'Start',
  End: 'End',
  Created: 'Created',
  Reason: 'Reason',
  NewBlackout: 'New Blackout',
  EditBlackout: 'Edit Blackout',
  Active: 'Active',
  Pending: 'Pending',
  Expired: 'Expired',
  WholeEnvironment: 'Whole Environment',
  AllOrigin: 'All from Origin',


  Deactivated: 'Deactivated',
  FromNumber: 'From Number',
  ToNumber: 'To Number',
  StartTime: 'Start Time',
  EndTime: 'End Time',
  Days: 'Days',

  // Notifications
  ReactivateDate: 'Reactivation date',
  ReactivateDateInfo: 'The date and time the notification rule will be automatically set to active if notification rule is inactive. Notification rules without reactivate time will remain inactive until it is manually set to active',
  NotificationChannel: 'Notification Channel',
  NotificationChannelId: 'Name used in Notification Rules to refer to the channel',
  NotificationChannelType: 'Supported SMS/Mail provider',
  NotificationKeyGenerate: 'Genereates New Encryption Key And Copies It To Clipboard',
  NotificationKey: 'Set NOTIFICATION_KEY="{New Key}" In Config To Use Generated Key',
  Verify: 'Verify',
  VerifyInfo: 'Verify SSL certificates of host. True/False',
  Host: 'Host',
  HostInfo: 'Url for sms/email provider',
  NotificationChannels: 'Notification Channels',
  NewNotificationChannel: 'Add New Notification Channel',
  NotificationChannelsInfo: 'Channels/Authentication for SMS/Mail providers',
  EditNotificationChannel: 'Edit Notification Channel',
  NotificationRuleHistoryId: 'Index of Notification Rule History',
  NotificationRuleHistoryUser: 'User that updated/created the Notification Rule',
  NotificationRuleHistory: 'Notification Rule History For',
  NotificationRuleHistoryInfo: ' {name/id of Notification Rule}',
  NotificationRuleHistoryTypeUpdate: 'Update: User manually updated the Notification Rule in Edit Notification Rule',
  NotificationRuleHistoryTypeCreate: 'Create: User created the Notification Rule in Add Notification Rule',
  NotificationRuleHistoryTypeReactivate: 'Reactivate: Automatic reactivation of notification rule',
  NotificationRuleHistoryCreateTime: 'Timestamp for when the update/creation of the Notification Rule happened',
  NotificationRuleHistoryCreateData: 'Data added/changed from previuos update',
  NotificationRule: 'Notification Rule',
  NotificationRules: 'Notification Rules',
  NotificationRulesInfo: 'Notfication Rules: Rules for sending out sms and mail through Notification Channels',
  NewNotificationRule: 'Add New Notification Rule',
  EditNotificationRule: 'Edit Notification Rule',
  NotificationHistory: 'Notification History',
  NotificationHistoryInfo: 'History of all SMS/Email sent',
  NotificationHistoryId: 'Id of record in SMS/Email provider. Can be used to find the record of the SMS/Email in the providers list of sent SMS/Emails',
  NotificationHistorySentTrue: 'True: SMS/Email provider has received and accepted the request',
  NotificationHistorySentFalse: 'False: SMS/Email provider has rejected/not received the request',
  NotificationHistorySentTime: 'Timestamp when the request was accepted/failed',
  NotificationHistoryMessage: 'Content of SMS/Email',
  NotificationHistoryReceiver: 'List of receivers that should receive SMS/Email',
  NotificationHistoryChannel: 'The ID of the Notification Channel used to send SMS/Email',
  NotificationHistoryRule: 'The Id of the Notification Rule that triggered the SMS/Email to be sent',
  NotificationHistoryAlert: 'The Id of the Alert that triggered the SMS/Email to be sent',
  NotificationHistoryError: 'Description of why the SMS/Email failed to be sent',
  EscalationRule: 'Escalation Rule',
  EscalationRuleInfo: 'Rules for escalating the severity of alerts',
  EscalationRules: 'Escalation Rules',
  NewEscalationRule: 'Add New Escalation Rule',
  EditEscalationRule: 'Edit Escalation Rule',
  NameInfo: 'User defined name for easy searchable field',
  ActiveInfoTrue: 'Active: Notification rule will send notifications if the incoming alert mathces the rule.',
  ActiveInfoFalse: 'Inactive: Notification rule will not send any notifications.',
  OnCall: 'On Call',
  OnCallInfo: 'Rules for which users and groups are On Call to receive SMS/Email when On Calls have been enabled in Notification Rules',
  NewOnCall: 'Add On Call',
  EditOnCall: 'Edit On Call',
  DelayTime: 'Delay Time',
  DelayTimeInfo: 'Time to delay before sending SMS/Email. If the alert changes status or severity, then the delayed notification is canceled',
  EscalationEnvironmentInfo: 'Alerts must have this environment to be escalated',
  EscalationTimeInfo: 'The time an alert must match the escalation rule before escalating the alert\'s severity',
  EscalationDaysInfo: 'Days the rule is escalating alerts that matches the rest of the rule. If the list is empty the rule is not restricted by days',
  EscalationStartTimeInfo: 'Time of day that the rule starts escalation of matching alerts. If Start and End is empty the rule is not restricted by time',
  EscalationEndTimeInfo: 'Time of day that the rule stop escalation of matching alerts. If Start and End is empty the rule is not restricted by time',
  EscalationServicesInfo: 'Alerts must include these services to be escalated. If list is empty there is no services required',
  EscalationResourceInfo: 'Alerts must have this resource to be escalated. If left empty, alerts can have any resource',
  EscalationEventInfo: 'Alerts must have this event to be escalated. If left empty, alerts can have any event',
  EscalationGroupInfo: 'Alerts must have this group to be escalated. If left empty, alerts can have any group',
  Interval: 'Interval',
  Receivers: 'Receivers',
  ReceiversInfo: 'Phone Numbers/Email Addresses that receive the SMS/Email',
  Sender: 'Sender',
  SenderInfo: 'Shortcode, Alphanumeric Sender ID, Email or phone number that receivers get the SMS/Email from',
  ReceiversAllInfo: 'Phone Numbers/Email addresses, Users, and Groups that receive the SMS/Email',
  UseOncall: 'Use On Calls',
  UseOnCallInfo: 'Send SMS/Email to Users and Groups that are active in On Calls',
  UseOnCallInfoTrue: 'True: Send SMS/Email to active Users and Groups in On Calls',
  UseOnCallInfoFalse: 'False: Ignore active users and groups in On Calls',
  FromSeverity: 'From Severity',
  ToSeverity: 'To Severity',
  Triggers: 'Triggers',
  TriggerInfo: 'Triggers are made up of three functions/logics and one field',
  EscalationTriggerInfo: 'Triggers are made up of two functions/logics',
  TriggerFromSeverity: 'The From Severity function represent which previous severities the incoming alert can have to trigger a notifcation',
  TriggerToSeverity: 'The To Severity function represent which new severities the incoming alert can have to trigger a notifcation',
  TriggerStatus: 'The Status function represents which statuses the incoming alert can have to trigger a notification',
  TriggerAnd: 'There is an AND relationship between functions (From Severity, To Severity and Status functions must match to trigger a notification).',
  EscalationTriggerAnd: 'There is an AND relationship between functions (From Severity, To Severity and Status functions must match to trigger a notification).',
  EscalationTriggerAnd: 'There is an AND relationship between functions (From Severity and To Severity functions must match to trigger a notification).',
  TriggerText: 'The text field represent the content of the SMS/Email that are sent',
  TriggerGroupsInfo:'Explanation of trigger logic (AnySeverity in Severity and Previuos Severity represent any severity, AnyStatus represent any status):',
  EscalationTriggerGroupsInfo:'Explanation of trigger logic (AnySeverity in Severity and Previuos Severity represent any severity):',
  EscalationTriggerInfo: 'Severities that trigger escalation',
  ExcludedTags: 'Excluded Tags',
  AlertDetail: 'Alert Detail',
  AlertDetailExample: 'Alert Detail used for all examples',
  TextFields: 'Both text fields are built up by plain text and references to alert details. Alert details are referenced by putting brackets around the alert detail field.',
  TextFieldObject:'If the detail field is of type object ({}), each value of a key inide of the object can be accessed by ".". For example "This is the ip: {attributes.ip}" wil result in "This is the ip: 127.0.0.1".',
  TextFieldFullObject: 'If objects are referenced, the entire object is inserted. For example "this is the all the attributes: {attributes}" will result in: "this is all the attibutes: {\'ip\': \'127.0.0.1\'}"',
  TextFieldArray: 'If the detail field is of type array ([]), each entry can be accessed by index. For example "this is a tag: {tags[0]}" will result in "this is a tag: test". If arrays are referenced a comma seperated list are inserted.',
  TextFieldFullArray: 'For example "this is all tags: {tags}" will result in "this is all tags: test, a, b"',
  TextExplained: 'The content of the sms/mail is decided from the notification rule text field and text field in the trigger that triggered the notificatoin rule',
  TextNotificationRule: 'The notification rule text field is added to the trigger text if trigger text is empty or trigger text includes "{default}" ',
  TextTrigger: 'The trigger text is the main content and only adds the notification rule text field if it is empty or includes "{default}"',
  TextBothEmpty: 'If both the trigger text and notification rule text is empty the default text:"{environment}: {severity} alert for {service} - {resource} is {event}" is used',
  TextExamples: 'Here is some examples of how the content of the notification sent is processed',
  TextFromNotificationRule: 'Text from notification rule',
  TextFromTrigger: 'Text from trigger',
  TagsInfo: 'Tags are organized into groups using a combination of AND and OR logic',
  ExcludedTagsInfo: 'Excluded tags are lists of tags that an alert can not have, meaning that if excluded tags have a match the alert is excluded from the action. \
  Excluded Tags are organized into groups using a combination of AND and OR logic.',
  TagsGroupsInfo:'Explanation of trigger logic (x in tags represent all tags that are not listed in either the OR or the AND function):',
  TagsAnd: 'The AND function represents tags that must all be present.',
  ExcludedTagsAnd: 'Alert can NOT have all of these tags',
  ExcludedTagsOr: 'Alert can NOT have any of these tags',
  TagsOr: 'The OR function represents tags where at least one must be present.',
  ExcludedTagsAnd: 'The AND function represents tags that must all be present to exclude the alert',
  ExcludedTagsOr: 'The OR function represents tags tags where at least one must be present to exclude the alert',
  TagsFunctionAnd: 'There is an AND relationship between functions/lists (AND and OR functions must both match to trigger).',
  ExcludedTagsFunctionAnd: 'There is an AND relationship between functions/lists (AND and OR functions must both match).',
  TagsGroupsOr: 'There is an OR relationship between groups (only one group needs to match)',
  ExcludedTagsGroupsOr: 'There is an OR relationship between groups (only one group needs to match to exclude the alert)',
  TagGroup: 'matches if the alert has:',
  ExcludedTagGroup: 'matches if alert has:',
  AndFunction: 'AND function',
  OrFunction: 'OR function',
  AlertFields: 'Alert Fields',
  AlertFieldsInfo: 'Fields in alert need to correspond with the following fields',
  ServicesInfo: 'List of services that the incoming alert must have to trigger notification. If list is empty there is no services required',
  EnvironmentInfo: 'Environment needs to match the environment of the incoming alert or be empty to trigger a notification.',
  ResourceInfo: 'Resource needs to match the resource of the incoming alert or be empty to trigger a notification.',
  EventInfo: 'Event needs to match the event of the incoming alert or be empty to trigger a notification.',
  GroupInfo: 'Group needs to match the group of the incoming alert or be empty to trigger a notification.',
  TextInfo: 'Text is the content of the SMS/Email to be sent.',
  DaysInfo: 'Days the rule is sending notifications. If the list is empty the rule is not restricted by days and sends notifications all days',
  StartTimeInfo: 'Time of day that the rule starts sending notifications. If Start and End is empty the rule sends notificatoin at any time',
  EndTimeInfo: 'Time of day that the rule stops sending notifications. If Start and End is empty the rule sends notificatoin at any time',
  RepeatDays: 'Days',
  RepeatWeeks: 'Weeks',
  RepeatMonths: 'Months',
  SelectOdd: 'Select Odd Weeks',
  SelectEven: 'Select Even Weeks',
  HideActive: 'Hide Active',
  ShowActive: 'Show Active',
  HideDeactivated: 'Hide Deactivated',
  ShowDeactivated: 'Show Deactivated',
  From: 'From',
  To: 'To',
  PreviousSeverity: 'Previous Severity',
  CurrentSeverity: 'Current Severity',

  // Customers
  LookUp: 'Look Up',
  LookUpDescription: 'Use login, Keycloak role, GitHub org, GitLab group or email domain',
  NewCustomer: 'New Customer',
  EditCustomer: 'Edit Customer',

  // Groups
  AddRemoveUsers: 'Add/Remove Users',
  Addusers: 'Add users',
  UsersInGroup: 'Users in group',
  NumberUsers: 'Number of Users',
  NewGroup: 'New Group',
  EditGroup: 'Edit Group',

  // Heartbeats
  Latency: 'Latency',
  Since: 'Since',

  // Manifest
  API: 'API',
  OpenGitHub: 'Open in GitHub',
  WebUI: 'Web UI',
  Build: 'Build',
  Date: 'Date',
  GitRevision: 'Git Revision',
  APIEndpoint: 'API Endpoint',

  // Perms
  Role: 'Role',
  Scope: 'Scope',
  SystemRole: 'System role',
  NewPermission: 'New permission',
  EditPermission: 'Edit permission',

  // Preferences
  ApplicationSettings: 'Application Settings',
  DarkTheme: 'Dark Theme',
  PlaySounds: 'Play notification sounds',
  LanguageSettings: 'Language settings',
  Languages: 'Languages',
  English: 'English',
  French: 'French',
  German: 'German',
  Turkish: 'Turkish',
  DateTimeSettings: 'Date and time settings',
  LongDate: 'Long date format',
  MediumDate: 'Medium date format',
  ShortTime: 'Short time format',
  DisplayMode: 'Display mode',
  UseLocal: 'Use local date & time',
  UseUTC: 'Use Coordinated Universal Time (UTC)',
  AlertSettings: 'Alert summary settings',
  ShowAllowedEnvs: 'Always show allowed environments',
  ShowNotesIcon: 'Show notes icon',
  ShowNotesHint: 'Show icon next to alert status to indicate an operator note',
  Font: 'Font',
  FontSize: 'Font Size',
  FontWeight: 'Font Weight',
  PageRows: 'Rows per page',
  rows: 'rows',
  ValueWidth: 'Value Width',
  DescriptionWidth: 'Description Text Width',
  RefreshInterval: 'Refresh interval',
  seconds: 'seconds',
  minutes: 'minutes',
  AckTimeout: 'Ack Timeout',
  ShelveTimeout: 'Shelve timeout',
  BlackoutSettings: 'Blackout period settings',
  BlackoutStartNow: 'Start blackout periods immediately',
  BlackoutPeriod: 'Blackout Period',
  hours: 'hours',

  // Profile
  Profile: 'Profile',
  UserID: 'User ID',
  PrimaryUserID: 'Primary User ID',
  Provider: 'Provider',
  EmailVerified: 'Email verified',
  EmailNotVerified: 'Email not verified',
  Customers: 'Customers',
  Organizations: 'Organizations',
  Groups: 'Groups',
  Roles: 'Roles',
  Scopes: 'Scopes',
  others: 'others',

  // Status
  LastUpdate: 'Last Update',
  Uptime: 'Uptime',
  Metric: 'Metric',
  Type: 'Type',
  Name: 'Name',
  Value: 'Value',
  AvgTime: 'Avg. Time',

  // Users
  Active: 'Active',
  Inactive: 'Inactive',
  Login: 'Login',
  Email: 'Email',
  Verified: 'Verified',
  Comment: 'Comment',
  VerifiedOrNot: 'Verified?',
  LastLogin: 'Last Login',
  NewUser: 'New user',
  EditUser: 'Edit user',

  // Reports
  Top: 'Top',
  Flapping: 'Flapping',
  Offenders: 'Offenders',
  Standing: 'Standing',
  TopFlappingDescription: 'Chattering and fleeting alert sources that have generated the most number of alert severity changes.',
  TopOffendersDescription: 'Frequently occurring alert sources that have generated the most number of alerts and alert duplicates.',
  TopStandingDescription: 'Stale alert sources that have active alerts for a prolonged duration.',
  
  Count: 'Count',
  DuplCount: 'Dupl. Count',
  Services: 'Services',
  Resources: 'Resources',

  // User Confirm
  Thanks: 'Thanks!',
  YouCanNowLogin1: 'You can now',
  YouCanNowLogin2: 'login.',
  EmailConfirmFailed: 'Sorry, there was a problem confirming your email address',
  TryAgain: 'Please try again',

  // User Forgot
  ResetLink: 'Enter your email and we\'ll send you a reset link',
  AlreadyHaveAccount: 'Already have an account?',
  CheckEmail: 'Check your email for a reset link...',
  ReturnSignIn: 'Return to Sign In',
  ResetEmailSent: 'Reset email sent successfully!',

  // User Login
  LoginToContinue: 'Log in to Alerta to continue',
  Username: 'Username',
  Password: 'Password',
  CreateAccount: 'Create Account',
  ForgotPassword: 'Forgot Password?',
  UnspecifiedProblem: 'Sorry, there was a problem',
  AuthWith: 'Authenticating with',
  AuthInProgress: 'Authenticating...',
  AuthNotPossible: 'Sorry, it is not possible to authenticate',
  AuthProviderUnknown: 'Unknown authentication provider',

  // User Logout
  LoggedOut: 'You have been logged out.',

  // User Reset
  ChooseNewPassword: 'Choose a new password',
  ConfirmPassword: 'Confirm Password',
  ResetPassword: 'Reset Password',

  // User Signup
  CreateAlertaAccount: 'Create your Alerta account',
  SignUpNotAvailable: 'Sorry, sign up is not currently available',
  FullName: 'Full Name',
  Min6Char: 'Min 6 characters',
  PasswordNotMatch: 'Passwords entered don\'t match',

  // Labels
  scope: 'scope',
  role: 'role',
  customer: 'customer',
  group: 'group',

  // General
  ConfirmDelete: 'Are you sure you want to delete this item?',
  Error: 'Error',
  Send: 'Send',
  Required: 'Required',
  Cancel: 'Cancel',
  Save: 'Save',
  NoDisplay: 'Sorry, nothing to display here :(',
  SearchNoResult1: 'Your search for ',
  SearchNoResult2: 'found no results.',
  OK: 'OK',

  // ENDS.
}
